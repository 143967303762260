import mixins from 'vue-typed-mixins'
import Section from '@/calendesk/sections/section/mixins/Section'
import Service from '@/calendesk/models/DTO/Response/Service'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import SectionConfigurationService from '@/calendesk/models/SectionConfigurationService'
import BasicUserSubscription from '@/calendesk/models/DTO/Response/BasicUserSubscription'
import { mapActions, mapGetters } from 'vuex'
import Category from '@/calendesk/models/DTO/Response/Category'
import cloneClassObject from '@/calendesk/tools/cloneClassObject'
import LocationController from '@/calendesk/controllers/LocationController'
import SelectableLocation from '@/calendesk/models/SelectableLocation'
import SelectableService from '@/calendesk/models/SelectableService'
import SelectableCategory from '@/calendesk/models/SelectableCategory'
import SelectableEmployee from '@/calendesk/models/SelectableEmployee'
import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import BookingFiltersRequestData from '@/calendesk/models/DTO/Request/BookingFiltersRequestData'
import BookingsResponse from '@/calendesk/models/DTO/Response/BookingsResponse'
import BookingTimeSlot from '@/calendesk/models/BookingTimeSlot'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import BookingCartSlotElement from '@/calendesk/models/BookingCartSlotElement'
import { Moment } from 'moment/moment'
import { SelectableLocationType } from '@/calendesk/models/SelectableLocationType'

export default mixins(Section).extend({
  data: () => ({
    isFetchingTimeSlots: false,
    selectedSelectableLocation: null as SelectableLocation | null,
    selectedSelectableService: null as SelectableService | null,
    selectedSelectableEmployee: null as SelectableEmployee | null,
    selectedDate: new Date().toISOString().substr(0, 10),
    customerTimeZone: null as null | string,
    minDate: new Date().toISOString().substr(0, 10),
    maxDate: null as string | null,
    numberOfDaysToFetchEvents: 62, // at least two months
    currentFetchStartDate: null as string | null,
    fetchAttempts: 0 as number,

    // Tells if the first date in the calendar was already set
    firstDateSet: false,

    // There is a feature that allows users to lock the fixed calendar dates
    lockDateChange: false,

    // Users can request date in the URL parameters, like `date`
    requestedDate: null as string | null,

    // If a user is logged, and requests the same service,
    // we should set the default employee as the one that user already had a meeting.
    preferredEmployeeIdFromTheLastBooking: null as null | number,
    preferredEmployeeIdFromTheLastBookingIsValid: false,
    checkedLastBookingForServiceId: null as null | number,
    isLoadingPreferredEmployee: false,

    // Used by calendars displaying services with multiple attendees (max_people > 1)
    // `countAllFreePlaces` enables displaying the total number of available spots for a day,
    // instead of just showing individual slots (one slot can have multiple bookings)
    countAllFreePlaces: false,

    // When a user clicks on a booking slot, we will keep it here
    selectedBookingSlot: null as BookingTimeSlot | null,

    // In case a calendar wants to use a cart for multiple bookings
    bookingCartSlots: [] as BookingCartSlotElement[]
  }),
  computed: {
    ...mapGetters({
      services: 'service/getServices',
      closedDialogData: 'dialog/getClosedDialogData',
      shouldReload: 'booking/getReloadAllBookings',
      categoryAndServices: 'service/getCategoryAndServices'
    }),
    serviceDurationBelowDay (): boolean {
      if (this.selectedSelectableService) {
        return this.selectedSelectableService.getDuration() <= 1435
      }

      return true
    },
    hideBookingEndTime (): boolean {
      return (
        this.draftConfiguration.wb_hide_booking_time_to__checkbox__ ||
        !this.serviceDurationBelowDay
      )
    },
    isCalendarPrevDisabled (): boolean {
      return (this.$moment(this.getStartDate, DateTimeFormats.FULL).isBefore(this.$moment())) ||
        !!(this.minDate && this.$moment(this.getStartDate, DateTimeFormats.FULL).isSameOrBefore(this.minDate))
    },
    isCalendarNextDisabled (): boolean {
      return !!(this.maxDate && this.$moment(this.getEndDate, DateTimeFormats.FULL).isSameOrAfter(this.maxDate))
    },
    getNumberOfDaysToFetchEvents (): number {
      return this.numberOfDaysToFetchEvents
    },
    getNextFetchDate (): string {
      const currentFetchStartDate = this.$moment(this.getEventsRequestStartDate(), DateTimeFormats.FULL)
        .add(this.getNumberOfDaysToFetchEvents, 'days')

      if (this.maxDate && currentFetchStartDate.isAfter(this.maxDate)) {
        return this.maxDate
      } else {
        return currentFetchStartDate.format(DateTimeFormats.FULL)
      }
    },
    canRetryEventsFetch (): boolean {
      return this.fetchAttempts <= 10 && this.currentFetchStartDate !== this.getNextFetchDate
    },
    getStartDate (): Moment {
      return this.$moment(this.selectedDate, DateTimeFormats.FULL).startOf('week')
    },
    getEndDate (): Moment {
      return this.$moment(this.selectedDate, DateTimeFormats.FULL).endOf('week')
    },
    isToday (): boolean {
      return this.$moment(this.selectedDate, DateTimeFormats.FULL)
        .startOf('day')
        .isSame(this.$moment().startOf('day'))
    },
    isCalendarReadyToDisplay (): boolean {
      return !!(
        (this.selectedSelectableLocation || this.getSelectableLocations.length === 0) &&
        this.selectedSelectableService &&
        this.selectedSelectableEmployee)
    },
    filteredServices (): Array<Service> {
      let services = this.services

      if (this.$route.query.employees) {
        const filteredEmployeeIds = String(this.$route.query.employees).split(
          ','
        )
        services = services.filter((service: Service) => {
          let employeeAssignedToService = false

          service.employees?.forEach((employee: Employee) => {
            if (filteredEmployeeIds.includes(String(employee.id))) {
              employeeAssignedToService = true
            }
          })

          return employeeAssignedToService
        })
      }

      const filteredServices = this.data.configuration
        .wb_service_list__services__

      let filteredServiceIds: string[] | null = null

      if (filteredServices) {
        filteredServiceIds = filteredServices.map(
          (item: SectionConfigurationService) => String(item.id)
        )
      }

      if (this.$route.query.services) {
        filteredServiceIds = String(this.$route.query.services).split(',')
      }

      if (filteredServiceIds !== null && filteredServiceIds.length > 0) {
        services = services.filter((service: Service) =>
          filteredServiceIds?.includes(String(service.id))
        )
      }

      if (
        this.data.configuration.wb_only_services_with_subscriptions__checkbox__
      ) {
        // When this option is enabled, we show services only if the user is logged in and has them in the subscriptions.
        let subscriptionServices = []

        if (
          this.user &&
          this.user.subscriptions &&
          this.user.subscriptions.length > 0
        ) {
          const allowedServiceIdsInSubscriptions: Array<number> = []
          this.user.subscriptions.forEach(
            (userSubscription: BasicUserSubscription) => {
              if (
                userSubscription.isActive() &&
                userSubscription.subscription &&
                userSubscription.subscription.serviceIds &&
                userSubscription.subscription.serviceIds.length > 0
              ) {
                userSubscription.subscription.serviceIds.forEach(
                  (id: number) => {
                    allowedServiceIdsInSubscriptions.push(id)
                  }
                )
              }
            }
          )

          subscriptionServices = services.filter((service: Service) =>
            allowedServiceIdsInSubscriptions.includes(service.id)
          )
        }

        return subscriptionServices
      }

      return services
    },
    filteredServicesGroupedByCategories (): Array<Category> {
      const result: Array<Category> = []
      const categories = cloneClassObject(this.categoryAndServices)

      categories.forEach((category: Category) => {
        category.services = this.filteredServices.filter((service: Service) => service.categoryId === category.id)

        if (this.selectedSelectableLocation) {
          category.services = category.services.filter((service: Service) => {
            // Check if any location of the service matches the selectedSelectableLocation type
            const serviceHasNoLocations = this.selectedSelectableLocation?.type === SelectableLocationType.NO_LOCATION &&
              (!service.locations || service.locations.length === 0)

            let serviceContainsSelectedLocationType = false

            if (!serviceHasNoLocations) {
              serviceContainsSelectedLocationType = service.locations.some(location =>
                LocationController.serviceLocationBelongsToSelectableLocationGroup(location, this.selectedSelectableLocation as SelectableLocation)
              )
            }

            return serviceHasNoLocations || serviceContainsSelectedLocationType
          })
        }

        result.push(category)
      })

      return result.filter((category: Category) => category.services && category.services.length > 0)
    },
    serviceEmployees (): Array<Employee> {
      if (
        this.selectedSelectableService &&
        this.selectedSelectableService.service &&
        this.selectedSelectableService.service.employees
      ) {
        if (this.$route.query.employees) {
          const filteredEmployeeIds = String(this.$route.query.employees).split(
            ','
          )

          return this.selectedSelectableService.service.employees.filter(
            (employee: Employee) =>
              filteredEmployeeIds.includes(String(employee.id))
          )
        }

        return this.selectedSelectableService.service.employees
      }

      return []
    },
    serviceLocations (): Array<ServiceLocation> {
      if (
        this.selectedSelectableService &&
        this.selectedSelectableService.service &&
        this.selectedSelectableService.service.locations
      ) {
        return this.selectedSelectableService.service.locations
      }

      return []
    },
    serviceMaxPeople (): number {
      if (this.selectedSelectableService && this.selectedSelectableService.service) {
        return this.selectedSelectableService.service.maxPeople
      }

      return 1
    },
    getSelectableServicesGroupedByCategories (): Array<SelectableCategory> {
      return (this.filteredServicesGroupedByCategories as Array<Category>)
        .reduce((acc: Array<SelectableCategory>, category: Category) => {
          const numberOfServices = category.services?.length || 0
          const selectableServices = category.services?.map(
            (service: Service) => new SelectableService(service, null, numberOfServices)
          ) || []

          if (selectableServices.length > 0) {
            acc.push(new SelectableCategory(selectableServices, category))
          }

          return acc
        }, [])
    },
    getSelectableLocations (): Array<SelectableLocation> {
      return LocationController.createSelectableLocationsFromServices(this.filteredServices)
    },
    getSelectableEmployees (): Array<SelectableEmployee> {
      const selectableEmployees: SelectableEmployee[] = []

      if (this.isSettingRandomEmployeeSupported || this.areEmployeesHidden) {
        selectableEmployees.push(new SelectableEmployee(null, true, this.serviceEmployees))
      }

      this.serviceEmployees.forEach((employee: Employee) => {
        selectableEmployees.push(new SelectableEmployee(employee))
      })

      return selectableEmployees
    },

    // Cart
    canUseCart (): boolean {
      return this.data.configuration.wb_use_booking_cart__checkbox__
    },
    isUsingCartWithServiceMaxOnePeople (): boolean {
      return this.data.configuration.wb_use_booking_cart__checkbox__ && this.serviceMaxPeople === 1
    },
    canUseCartStepper (): boolean {
      return this.data.configuration.wb_use_booking_cart__checkbox__ && this.serviceMaxPeople > 1
    }
  },
  methods: {
    ...mapActions({
      fetchAvailableBookingSlots: 'booking/fetchAvailableBookingSlots',
      setReloadAllBookings: 'booking/setReloadAllBookings',
      fetchBookings: 'booking/fetchAll'
    }),
    initCalendar () {
      this.checkTheTimeZone()
      this.checkIfUserRequestedDate()
      this.checkIfShouldForceCalendarToDateRange()
    },
    hasAvailableTimeSlots (
      timeSlots: Record<string, any> | null,
      selectedEmployeeId: number|null|undefined,
      checkForBooked = false): boolean {
      if (timeSlots) {
        if (selectedEmployeeId) {
          return Object.values(timeSlots[selectedEmployeeId] || {}).some((hours: any) =>
            checkForBooked ? hours.some((slot: any) => !slot.booked) : hours.length > 0
          )
        } else {
          return Object.values(timeSlots).some((employeeSlots: any) =>
            Object.values(employeeSlots).some((hours: any) =>
              checkForBooked ? hours.some((slot: any) => !slot.booked) : hours.length > 0
            )
          )
        }
      }

      return false
    },
    checkIfShouldForceCalendarToDateRange () {
      const forceMinDate = this.data.configuration.wb_calendar_min_date__date__ as string | null | undefined
      const forceMaxDate = this.data.configuration.wb_calendar_max_date__date__ as string | null | undefined

      if (forceMinDate || forceMaxDate) {
        const minDate = forceMinDate ? this.$moment(forceMinDate) : this.$moment()
        const maxDate = forceMaxDate ? this.$moment(forceMaxDate) : null
        const currentDate = this.$moment()

        if (currentDate.isBefore(minDate)) {
          this.selectedDate = minDate.format(DateTimeFormats.FULL)
        } else if (maxDate && currentDate.isAfter(maxDate)) {
          this.selectedDate = maxDate.format(DateTimeFormats.FULL)
        } else {
          this.selectedDate = currentDate.format(DateTimeFormats.FULL)
        }

        this.minDate = minDate.format(DateTimeFormats.FULL)

        if (maxDate) {
          if (maxDate.isBefore(minDate)) {
            this.maxDate = minDate.format(DateTimeFormats.FULL)
          } else {
            this.maxDate = maxDate.format(DateTimeFormats.FULL)
          }
        }

        this.lockDateChange = true
      }
    },
    checkIfUserRequestedDate () {
      if (this.$route.query.date) {
        const date = this.$moment(this.$route.query.date as string, DateTimeFormats.FULL)

        if (date.isValid()) {
          this.selectedDate = this.$moment(this.$route.query.date as string).format(
            DateTimeFormats.FULL
          ) as string

          this.requestedDate = this.selectedDate
        }
      }
    },
    checkTheTimeZone () {
      if (!this.isTimeZoneHidden) {
        this.customerTimeZone = this.guessTimeZone()
      }
    },
    reloadPreferredEmployee () {
      if (this.isUserLogged &&
        !this.isLoadingPreferredEmployee &&
        this.selectedSelectableService &&
        this.selectedSelectableService.service &&
        this.checkedLastBookingForServiceId !== this.selectedSelectableService.service.id) {
        this.checkedLastBookingForServiceId = this.selectedSelectableService.service.id
        this.isLoadingPreferredEmployee = true
        const data = new BookingFiltersRequestData()
        data.limit = 1
        data.ascending = 0
        data.order_by = 'id'
        data.service_ids = `${this.selectedSelectableService.service.id}`

        this.fetchBookings(data).then((bookings: BookingsResponse) => {
          if (bookings.data && bookings.data.length > 0 && bookings.data[0].employee) {
            const bookingEmployeeId = bookings.data[0].employee.id
            const found = this.getSelectableEmployees
              .find((selectableEmployee: SelectableEmployee) => selectableEmployee.employee?.id === bookingEmployeeId)

            if (found) {
              this.preferredEmployeeIdFromTheLastBookingIsValid = false
              this.preferredEmployeeIdFromTheLastBooking = bookings.data[0].employee.id
            }
          }

          this.isLoadingPreferredEmployee = false
        })
      }
    },
    setToday () {
      this.selectedDate = this.$moment().format(DateTimeFormats.FULL)
    },
    countSpots (dateValues: any[]) {
      let result = 0

      if (this.countAllFreePlaces &&
        this.selectedSelectableService &&
        this.selectedSelectableService.service.maxPeople > 1) {
        const allSpots = dateValues.length * this.selectedSelectableService.service.maxPeople
        let usedSpots = 0
        dateValues.forEach((spot: Record<string, any>) => {
          usedSpots += spot.used
        })
        result = allSpots - usedSpots
      } else {
        result = dateValues.length
      }

      return result
    },
    getEmployeeById (id: string|number) {
      if (typeof id === 'string') {
        id = parseInt(id)
      }

      const employee = this.serviceEmployees.find((employee: Employee) => employee.id === id)

      if (employee) {
        return employee
      }
    },
    getEventsRequestStartDate (): string {
      return this.getEventsRequestStartDateMoment().format(DateTimeFormats.FULL)
    },
    getEventsRequestStartDateMoment (): Moment {
      const startDate = this.currentFetchStartDate || this.getStartDate
      const momentStartDate = this.$moment(startDate, DateTimeFormats.FULL)
      const now = this.$moment().startOf('day')
      const momentMinDate = this.$moment(this.minDate, DateTimeFormats.FULL)
      let effectiveStartDate = momentStartDate.isBefore(now) ? now : momentStartDate
      effectiveStartDate = effectiveStartDate.isBefore(momentMinDate) ? momentMinDate : effectiveStartDate
      return effectiveStartDate
    },
    getAdjustedEventsRequestStartDate (eventsRequestStartDate: string, maxBookingDaysForService: number): number {
      const defaultEventsNumberOfDays = this.getEventsNumberOfDays()
      const requestStartDateMoment = this.$moment(eventsRequestStartDate).startOf('day')

      const daysPassedSinceToday = requestStartDateMoment.diff(this.$moment().startOf('day'), 'days')
      const remainingBookingDays = maxBookingDaysForService - daysPassedSinceToday
      return Math.min(defaultEventsNumberOfDays, remainingBookingDays)
    },
    validatePreferredEmployeeIdFromTheLastBooking (
      timeSlots: Record<string, any> | null,
      employeeId: number | null | undefined,
      checkForBooked = false) {
      if (!this.preferredEmployeeIdFromTheLastBookingIsValid &&
        this.preferredEmployeeIdFromTheLastBooking &&
        employeeId === this.preferredEmployeeIdFromTheLastBooking) {
        const timeSlotsForThePreferredEmployeeExists = this.hasAvailableTimeSlots(
          timeSlots,
          employeeId,
          checkForBooked
        )

        if (!timeSlotsForThePreferredEmployeeExists) {
          // Reset the selected employee id if no spots are available.
          this.preferredEmployeeIdFromTheLastBooking = null
        } else {
          // If this is true, it means that this employee had any time slots and we should not restart it.
          this.preferredEmployeeIdFromTheLastBookingIsValid = true
        }
      }
    },
    cleanEventsFetchSettings () {
      this.fetchAttempts = 0
      this.currentFetchStartDate = null
      this.isFetchingTimeSlots = false
    },
    getEventsNumberOfDays (): number {
      let numberOfDays = this.numberOfDaysToFetchEvents || 10

      if (this.lockDateChange && this.minDate && this.maxDate) {
        const maxDate = this.$moment(this.maxDate)
        const startDate = this.$moment(this.getEventsRequestStartDate())

        const maxNumberOfDays = maxDate.diff(startDate, 'days') + 1

        if (maxNumberOfDays < numberOfDays) {
          numberOfDays = maxNumberOfDays
        }
      }

      return numberOfDays
    },

    // Cart
    isInCart (slot: BookingTimeSlot): boolean {
      return !!this.bookingCartSlots.find((item) => item.key === this.getKeyForSlot(slot))
    },
    countCartOccurrences (slot: BookingTimeSlot): number {
      return this.getTheSameSlots(slot).length
    },
    getTheSameSlots (slot: BookingTimeSlot): BookingCartSlotElement[] {
      return this.bookingCartSlots.filter((item) => item.key === this.getKeyForSlot(slot))
    },
    countLeftSpots (slot: BookingTimeSlot): number {
      // Special case for the same booking slot and the same employee selected.
      // We don't want to have the situation where a double click on a single spot
      // will add two identical bookings.
      const cartOccurrences = this.countCartOccurrences(slot)

      if (cartOccurrences > 0 && this.isUsingCartWithServiceMaxOnePeople) {
        return 0
      }

      const freeSlots = (slot.max - slot.used)
      return (freeSlots - cartOccurrences)
    },
    canAddToCart (slot: BookingTimeSlot): boolean {
      return this.countLeftSpots(slot) > 0
    },
    getKeyForSlot (slot: BookingTimeSlot): string {
      if (this.selectedSelectableService) {
        let serviceTypeId = 0
        if (this.selectedSelectableService.serviceType) {
          serviceTypeId = this.selectedSelectableService.serviceType.id
        }
        return this.selectedSelectableService.service.id + '_' + serviceTypeId + '_' + slot.date + '_' + slot.time
      }

      return slot.date + '_' + slot.time
    },
    bookFromCart () {
      const events = this.bookingCartSlots.map((slot) => slot.event)

      this.openDialog(new Dialog(
        true,
        DialogTypes.BOOKING_FORM_DIALOG,
        null,
        DialogSize.MIDDLE,
        false,
        'center',
        {
          events: events,
          configurationData: this.data
        },
        true))
    }
    // End of cart, add to cart, remove from cart should be implemented per calendar
  }
})
